<template>
  <b-row class="about-row">
    <b-col cols="4" class="px-1">
      <b-card
        class="std-border mb-0"
        header-bg-variant="success"
        header-text-variant="white"
      >
        <div slot="header">Holding Company Structure</div>
        <b-card-body class="p-0">
          <div>
            Download list of all Banks/CUs with BHC structure.
          </div>

          <div class="about-footer">
            <div class="about-footer-content">
              <b-button
                size=""
                variant="secondary"
                @click="downloadFile('bankList')"
                >Download</b-button
              >
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col cols="4" class="px-1">
      <b-card
        class="std-border mb-0"
        header-bg-variant="success"
        header-text-variant="white"
      >
        <div slot="header">Merger History</div>
        <b-card-body class="p-0">
          <div>
            Download list of all acquisitions by Bank/CU/BHC.
          </div>

          <div class="about-footer">
            <div class="about-footer-content">
              <b-button
                size=""
                variant="secondary"
                @click="downloadFile('mergers')"
                >Download</b-button
              >
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'BulkDownload'
}
</script>
